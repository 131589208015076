<template>
  <div class="stack-order page">
    <h1 class="page-title">
      {{ $t('title') }}
    </h1>
    <!--    <transition name="fade" mode="out-in">-->
    <div v-if="loading && !list.length" class="stack-order__loading">
      <base-loader />
    </div>
    <div v-else-if="list.length" class="stack-order__content">
      <!--      <page-block :title="$t('choise')" size="bigger">-->
      <page-block size="bigger">
        <pricelist-block :list="list">
          <template v-slot:item="{ item }">
            <pricelist-card
              :id="item.id"
              :title="item.name"
              :list="getSpecs(item)"
              :price="item.cost"
              :cloud="true"
              :desc="sanitize(item.description)"
              :disabled="needPhoneValidate || !balance"
              :active="current && current.id === item.id"
              @click="
                needPhoneValidate
                  ? verificationPhone($t('sure.title'), $t('sure.textConfirmations'), phone, code)
                  : routeTo(item)
              "
            />
          </template>
        </pricelist-block>
      </page-block>
      <page-block v-if="needPhoneValidate || balance < 1" size="bigger">
        <p v-if="needPhoneValidate">
          <label class="typo__label medium-title"
            >{{ $t('phone') }}
            <plain-button :to="{ name: 'ProfilePersonal' }" color="primary">профиля</plain-button>
          </label>
        </p>
        <p v-if="!balance">
          <label class="typo__label medium-title">{{ $t('balance') }}</label>
        </p>
      </page-block>
      <transition name="fade" mode="out-in">
        <router-view @change-period="changePeriod($event)"> </router-view>
      </transition>
    </div>
    <!--    </transition>-->
  </div>
</template>

<script>
import PricelistBlock from '@/components/Configurator/components/PricelistBlock';
import PricelistCard from '@/components/Configurator/components/PricelistCard';
import showErrorModal from '@/mixins/showErrorModal';
import Vue from 'vue';
import VueScrollTo from 'vue-scrollto';
import gtmMixin from '@/mixins/gtmMixin';
import phoneVerification from '@/mixins/phoneVerification';
import updatePhone from '@/mixins/updatePhone';
Vue.use(VueScrollTo);
export default {
  name: 'StackOrder',
  components: { PricelistBlock, PricelistCard },
  mixins: [showErrorModal, gtmMixin, phoneVerification, updatePhone],
  data() {
    return {
      configData: null,
      code: '',
      period: 1,
      currentPeriod: 1,
      cost: 0,
      costDetails: null,
      isCalculating: false,
      isSendingToBasket: false,
      isSendingToPay: false,
      // currentUrlPeriod: 1,
    };
  },
  computed: {
    needPhoneValidate() {
      return this.$store.getters['moduleProfile/need_phone_validate'];
    },
    list() {
      this.addDataLayerPriceData(this.$store.state.moduleStack.moduleStackOrder.list, 'OpenStack');
      return this.$store.state.moduleStack.moduleStackOrder.list;
    },
    loading() {
      return this.$store.state.moduleStack.moduleStackOrder.loading;
    },
    current() {
      return this.$store.getters['moduleStack/moduleStackOrder/current'] || this.currentItem;
    },
    getCurrentPeriod() {
      return this.currentPeriod === this.period ? this.period : this.currentPeriod;
    },
    balance() {
      return +this.$store.getters['moduleProfile/balance'] > 0;
    },
    id() {
      if (this.$route.path === '/stack/order/plans') {
        return this.list[0].id;
      } else {
        return this.$route.params.id ? +this.$route.params.id : this.current.id;
      }
    },
    currentItem() {
      return this.list.find(i => i.id === +this.id);
    },
    phone() {
      return this.$store.state.moduleProfile.profile.phone;
    },
  },
  watch: {
    needPhoneValidate: {
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.routeTo(this.list[0]);
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.updatePhoneInfo();
    if (!this.current) return this.fetchPricelist();
    if (!this.needPhoneValidate) this.routeTo(this.current);
  },
  beforeRouteLeave(_to, _from, next) {
    this.$store.dispatch('moduleStack/moduleStackOrder/reset');
    next();
  },
  methods: {
    routeTo(item) {
      this.clickToGtm(item, 'OpenStack');
      this.viewDetailGtm(item, 'OpenStack');
      // this.setCurrent(item);
      // this.fetchParams(item, this.period);
      // this.updateConfig();
      this.$router
        .push({
          name: 'stackOrderDetails',
          params: { id: item.id, period: this.period },
        })
        .catch(err => {
          if (
            err.name !== 'NavigationDuplicated' &&
            !err.message.includes('Avoided redundant navigation to current location')
          ) {
            this.showErrorModal(err);
          }
        });
    },
    // api and logic methods
    fetchPricelist() {
      this.$store.dispatch('moduleStack/moduleStackOrder/fetchPricelist');
    },
    fetchCalc(data) {
      return this.$store.dispatch('moduleStack/moduleStackOrder/fetchCalc', data);
    },
    setCurrent(item) {
      this.$store.dispatch('moduleStack/moduleStackOrder/setCurrent', item.id);
    },
    fetchParams(item, period) {
      const params = {};
      if (item) params.id = item.id;
      if (period) params.period = period;
      return this.$store.dispatch('moduleStack/moduleStackOrder/fetchParams', params);
    },
    updateConfig(data) {
      this.configData = { ...data };
    },
    getSpecs(item) {
      const list = [];
      for (let prop of Object.keys(item.specs)) {
        list.push(
          this.$t(`specs.${prop}`, {
            n: item.specs[prop],
            i: item.name.includes('Ultra') ? 'NVME' : 'SSD',
          })
        );
      }
      return list;
    },
    showSuccessModal(text) {
      this.$modals.open({
        name: 'SuccessOrder',
        size: 'small',
        text,
      });
    },
    changePeriod(period) {
      this.currentPeriod = +period;
      this.$router.push({
        name: 'stackOrderDetails',
        params: { id: this.current.id, period: period },
      });
    },
    sanitize(html) {
      let tmp = document.createElement('DIV');
      tmp.innerHTML = html;
      let res = tmp.textContent || tmp.innerText || '';
      res.replace('\u200B', ''); // zero width space
      res = res.trim();
      return res;
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "title": "Создание Публичного облака",
    "choise": "Выберите тариф",
    "additional": "Дополнительно",
    "summary": "Итого",
    "phone": "Для заведения новой услуги, необходимо иметь подтвержденный номер телефона. Сделать это можно в разделе",
    "balance": "Ваш баланс должен быть положительным",
    "payfor": "при оплате за",
    "pay": "Оплатить",
    "order": "В корзину",
    "sure": {
      "title": "Активация Облачный бекап",
      "textConfirmations": "Чтобы продолжить заказ необходимо подтвердить контактные данные. ",
      "tooltip": "Внимание! Телефон не подтвержден.",
      "send": "Подтвердить",
      "confirm": "Подтвердить",
      "text": "Код отправлен",
      "repeat": "Новый код",
      "success": "Вы успешно подтвердили номер телефона"
    },
    "specs": {
      "disc": "{n} ГБ {i}",
      "mem": "{n} ГБ RAM",
      "ncpu": "{n} CPU"
    },
    "success": {
      "basket": "Заказ на {num} успешно добавлен в корзину",
      "pay": "Сервер успешно создан, {num} списано с лицевого счета"
    },
    "needbalance": "Необходимо пополнить баланс"
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.stack-order {
  &__loading {
    flexy(center, center);
    flex: 1 1 100%;
  }
  &__sum {
    flex: 0 0 100%;

    +breakpoint(ms-and-up) {
      flex: 0 0 auto;
      margin-right: 0.5rem;
    }
  }
  &__price {
    flexy(flex-start, center, wrap);

    &-text {
      margin-right: 0.5rem;

      +breakpoint(sm-and-up) {
        font-size: $font-size-bigger;
      }
    }
  }
  &__period {
    flex: 0 0 8.3rem;

    +breakpoint(sm-and-up) {
      flex: 0 0 10rem;
    }
  }

  &__actions {
    margin: 0.75rem -0.75rem -0.75rem;
    flexy(flex-start, center, wrap);
  }

  &__btn {
    margin: 0.75rem;

    +breakpoint(xs-only) {
      flex: 1 1 100%;
    }
  }
}
</style>
